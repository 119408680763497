import React from "react"
import Logo from "../assets/logo.svg"
import "./header.css"

const Header = () => (
  <header id='header'>
    <Logo />
  </header>
)

export default Header
